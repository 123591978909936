@use "breakpoints";

button {
  font-family: "ABCMonumentGroteskSemi-Mono", monospace;
  background-color: transparent;
  border: 0.15rem solid #000000;
  color: #000000;
  border-radius: 2em;
  padding: 0.5em 1em;

  cursor: pointer;

  &:hover {
    @media (hover: hover) {
      background-color: rgba(255, 255, 255, 0.75);
    }
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.75);
  }

  &.large {
    font-size: 1.2rem;

    @media (min-width: breakpoints.$sm) {
      font-size: 1.5rem;
    }
  }

  &.small {
    padding: 0.25em 1em;
    border-width: 0.08rem;

    font-size: 0.84rem;

    @media (min-width: breakpoints.$sm) {
      font-size: 0.75rem;
    }
  }
}

.back-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
}
