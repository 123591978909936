@use "breakpoints";

.max-width {
  max-width: min(100%, 50rem);
}

// check single news item if changing this
.med-width {
  max-width: min(100%, 38rem);
}

body,
html {
  font-size: 18px;
}

@media screen and (min-width: breakpoints.$md) {
  body,
  html {
    font-size: 20px;
  }
}

@media screen and (min-width: breakpoints.$xl) {
  body,
  html {
    font-size: 24px;
  }
}
