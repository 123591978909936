@use "breakpoints";
@use "colors";
// @import "swiper/swiper-bundle.css";

html {
  --navbarHeight: 4rem;
  margin-top: -1px;
}

.pointer {
  cursor: pointer;
  user-select: none;
}

hr {
  border: 0.02rem solid #000000;
  margin: 0.5rem 0;
}

.cover {
  box-shadow: 5px 5px 6px 0 rgb(30 44 48 / 15%);
  // box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
}

.grey {
  color: colors.$jovis-grey;
}

.full-height {
  height: 100vh;
}

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: calc(breakpoints.$sm - 1px)) {
  .sm-up {
    display: none !important;
  }
}

@media (min-width: breakpoints.$sm) {
  .sm-down {
    display: none !important;
  }
}

@media (max-width: calc(breakpoints.$md - 1px)) {
  .md-up {
    display: none !important;
  }
}

@media (min-width: breakpoints.$md) {
  .md-down {
    display: none !important;
  }
}
