// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "scss/fonts";
@use "scss/globals";
@use "scss/buttons";
@use "scss/typography";
@use "scss/scale-all";
@use "scss/colors";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$jovis-frontend-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$jovis-frontend-accent: mat.m2-define-palette(mat.$m2-grey-palette, 900, A100, A400);

// The warn palette is optional (defaults to red).
$jovis-frontend-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$jovis-frontend-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $jovis-frontend-primary,
      accent: $jovis-frontend-accent,
      warn: $jovis-frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($jovis-frontend-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "ABCMonumentGrotesk", sans-serif;
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
  --safe-area-inset-right: env(safe-area-inset-right);
  padding: var(--safe-area-inset-top) var(--safe-area-inset-right)
    var(--safe-area-inset-bottom) var(--safe-area-inset-left);

  transition: background-color 0.5s ease, color 0.5s ease;
}

@import url("keen-slider/keen-slider.scss");
@import "scss/wp.scss";

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #181818;
}

.svg-container.rotate svg {
  transform: rotate(180deg);
}

.svg-container.flipv svg {
  transform: scale(1, -1);
}

.svg-container svg {
  transition: transform 0.3s ease;
  transform-origin: 50% 50%; /* adjust as needed */
}

::placeholder {
  color: colors.$jovis-placeholder !important;
}
