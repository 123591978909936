.wp-block-columns {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.wp-block-column {
  flex: 1;
  min-width: 15rem;
}

.is-layout-flex {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  // margin-bottom: 2rem;
}

.is-layout-flow {
  // display:;
  // flex-direction: column;
  // margin-bottom: 2rem;
}

ul {
  padding-inline-start: 0.7rem;
  margin: 0;
}
