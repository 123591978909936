
@font-face {
  font-family: 'ABCMonumentGrotesk';
  src: url('../assets/fonts/ABCMonumentGrotesk-Regular.woff2') format('woff2'),
       url('../assets/fonts/ABCMonumentGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABCMonumentGrotesk';
  src: url('../assets/fonts/ABCMonumentGrotesk-Bold.woff2') format('woff2'),
       url('../assets/fonts/ABCMonumentGrotesk-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ABCMonumentGrotesk';
  src: url('../assets/fonts/ABCMonumentGrotesk-RegularItalic.woff2') format('woff2'),
       url('../assets/fonts/ABCMonumentGrotesk-RegularItalic.woff') format('woff');  
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ABCMonumentGrotesk';
  src: url('../assets/fonts/ABCMonumentGrotesk-BoldItalic.woff2') format('woff2'),
       url('../assets/fonts/ABCMonumentGrotesk-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ABCMonumentGroteskSemi-Mono';
  src: url('../assets/fonts/ABCMonumentGroteskSemi-Mono-Regular.woff2') format('woff2'),
       url('../assets/fonts/ABCMonumentGroteskSemi-Mono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABCMonumentGroteskSemi-Mono';
  src: url('../assets/fonts/ABCMonumentGroteskSemi-Mono-RegularItalic.woff2') format('woff2'),
       url('../assets/fonts/ABCMonumentGroteskSemi-Mono-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
