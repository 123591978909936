@use "breakpoints";

$small-title-size: 1.54rem;
$large-title-size: 2.6rem;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
  line-height: 1;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.15em;
    text-decoration-thickness: 0.07em;
    @media (hover: none) {
      text-decoration: none;
    }
  }
}

h1.title {
  margin: 1rem 0;
  font-size: 3rem;
  @media (max-width: breakpoints.$sm) {
    font-size: 2rem;
  }
  font-weight: normal;
  line-height: 1.1;
  text-align: center;
  // white-space: pre-wrap;
  text-wrap: balance;
  // word-break: break-word;
  text-underline-offset: 0.1em;

  &.left {
    text-align: left;
  }
}

.balance {
  #{'text-wrap'}: balance;
}

h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.4;
  // white-space: pre-wrap;
}

small {
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1.4;
  // white-space: pre-wrap;
}

.big {
  font-size: 1.4rem;
  @media (min-width: breakpoints.$sm) {
    font-size: 1.5rem;
  }
}

.mono {
  font-family: "ABCMonumentGroteskSemi-Mono", monospace;

  &.large {
    font-size: 1.2rem;

    @media (min-width: breakpoints.$sm) {
      font-size: 1.5rem;
    }
  }

  &.small {
    font-size: 0.84rem;

    @media (min-width: breakpoints.$sm) {
      font-size: 0.75rem;
    }
  }

  &.tiny {
    font-size: 0.75rem;
    @media (min-width: breakpoints.$sm) {
      font-size: 0.66rem;
    }
  }
}
